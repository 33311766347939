<template>
  <div class="flex flex-col min-h-screen">
    <header class="w-full border-b border-neutral-200">
      <UContainer class="flex items-center min-h-16">
        <NuxtLink class="logo" :to="`/${locale}/`">
          <Logo />
        </NuxtLink>
        <UButton v-if="access" class="w-32 ml-auto justify-center" @click="logout">Logout</UButton>
      </UContainer>
    </header>
    <main class="flex flex-col flex-grow">
      <slot />
    </main>
  </div>
</template>

<script setup lang="ts">
import Logo from '~/assets/icons/logo.svg'
const { locale } = useI18n()

useHead({
  meta: [{ name: 'robots', content: 'noindex, nofollow' }],
  htmlAttrs: {
    class: 'theme--base',
  },
})

const access = ref(false)

onMounted(() => {
  access.value = !!sessionStorage.getItem('utilsToken')
})

const logout = () => {
  sessionStorage.removeItem('utilsToken')
  access.value = false
  navigateTo(`/${locale.value}/utils`)
}
</script>
